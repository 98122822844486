import React from "react"

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import { useStaticQuery, Link, graphql } from "gatsby"

const useStyles = makeStyles(styles);

export default function Lucilade(props) {
    const classes = useStyles();

    return (
        <Card>
            <CardBody>
                <Link to={props.data.slug.current}>
                    <h4 className={classes.cardTitle}>
                        {props.data.content}
                    </h4>
                </Link>
            </CardBody>
            <CardFooter>
                <div className={classes.author}>
                    <span> {props.data.author}</span>
                </div>
                {props.data.publishedAt && <div className={classes.stats + " " + classes.mlAuto}>
                    {props.data.publishedAt}
                </div>}
            </CardFooter>
        </Card >
    )
}